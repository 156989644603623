import { DETAIL_SCREEN_KBN } from '@/common/const.js'
//import { openMsgModal } from '@/common/Common.js'
import OperationTimePatternDetail from './OperationTimePatternDetailInfo'

export function init(obj) {
  if (obj.getDatas[0].detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //初期化
    obj.operationTimePatternDetail = new OperationTimePatternDetail()
  } else {
    //稼動時間パターン明細ＩＤ
    obj.operationTimePatternDetail.operationTimePatternDetailId =
      obj.getDatas[0].selectedItem[0].operation_time_pattern_detail_id
    // 表示順
    obj.operationTimePatternDetail.displayOrder =
      obj.getDatas[0].selectedItem[0].display_order
    // 輸送形態区分
    obj.operationTimePatternDetail.transportTypeKind =
      obj.getDatas[0].selectedItem[0].transport_type_kind
    // 自動配車対象外フラグ
    obj.operationTimePatternDetail.autoPlanningExcludeFlag =
      obj.getDatas[0].selectedItem[0].auto_planning_exclude_flag
    // 開始曜日
    obj.operationTimePatternDetail.beginWeekday =
      obj.getDatas[0].selectedItem[0].begin_weekday
    // 開始時刻
    obj.operationTimePatternDetail.beginTime = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].begin_time
    )
    // 終了曜日
    obj.operationTimePatternDetail.endWeekday =
      obj.getDatas[0].selectedItem[0].end_weekday
    // 終了時刻
    obj.operationTimePatternDetail.endTime = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].end_time
    )
    // 担当方面１
    obj.operationTimePatternDetail.chargeAreaId01 =
      obj.getDatas[0].selectedItem[0].charge_area_id01
    obj.operationTimePatternDetail.chargeAreaName01 =
      obj.getDatas[0].selectedItem[0].charge_area_name01

    // 担当方面２
    obj.operationTimePatternDetail.chargeAreaId02 =
      obj.getDatas[0].selectedItem[0].charge_area_id02
    obj.operationTimePatternDetail.chargeAreaName02 =
      obj.getDatas[0].selectedItem[0].charge_area_name02

    // 担当方面３
    obj.operationTimePatternDetail.chargeAreaId03 =
      obj.getDatas[0].selectedItem[0].charge_area_id03
    obj.operationTimePatternDetail.chargeAreaName03 =
      obj.getDatas[0].selectedItem[0].charge_area_name03

    // 担当方面４
    obj.operationTimePatternDetail.chargeAreaId04 =
      obj.getDatas[0].selectedItem[0].charge_area_id04
    obj.operationTimePatternDetail.chargeAreaName04 =
      obj.getDatas[0].selectedItem[0].charge_area_name04

    // 担当方面５
    obj.operationTimePatternDetail.chargeAreaId05 =
      obj.getDatas[0].selectedItem[0].charge_area_id05
    obj.operationTimePatternDetail.chargeAreaName05 =
      obj.getDatas[0].selectedItem[0].charge_area_name05

    // 削除済み区分
    obj.operationTimePatternDetail.deleted =
      obj.getDatas[0].selectedItem[0].deleted
    // 登録日時
    obj.operationTimePatternDetail.registrationTimeDisplay =
      obj.getDatas[0].selectedItem[0].registration_time_display
    // 登録ユーザ
    obj.operationTimePatternDetail.registrationUser =
      obj.getDatas[0].selectedItem[0].registration_user
    // 更新日時
    obj.operationTimePatternDetail.updateTimeDisplay =
      obj.getDatas[0].selectedItem[0].update_time_display
    // 更新ユーザ
    obj.operationTimePatternDetail.updateUser =
      obj.getDatas[0].selectedItem[0].update_user
  }

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function changeDisplayTime(hhmmss) {
  let time = hhmmss.match(/\d{2}/g).join(':')
  return time
}

export async function add(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  //  let data = obj.operationTimePatternDetail
  // var result = dateCheck(
  //   data.beginTime,
  //   data.endTime,
  //   data.beginWeekday,
  //   data.endWeekday
  // )
  // if (result != null) {
  //   // 期間日付の入力に不整合があります。
  //   openMsgModal(
  //     obj,
  //     MSG_TYPE.W,
  //     obj.$store.getters['init/getMessage']('YZ00MG992E')
  //   )
  //   //obj.$bvModal.hide('edit-modal')
  //   return
  // }
  obj.$bvModal.hide('edit-modal')
  obj.$emit('after-close-set', obj.operationTimePatternDetail)
}

// /**
//  * 稼動時間が5日を越える場合、エラーとする
//  * @param {*} startT
//  * @param {*} endT
//  * @param {*} beginWeekday
//  * @param {*} endWeekday
//  */
// function dateCheck(startT, endT, beginWeekday, endWeekday) {
//   var days = beginWeekday - endWeekday

//   var startTime = changeDate(startT)
//   var endTime = changeDate(endT)
//   let sec = startTime - endTime

//   if (0 < days || days < -5) {
//     return 'error'
//   }
//   days = Math.abs(days)
//   sec = Math.abs(sec)
//   var betweenDays = minsBetween(days, sec)
//   let error

//   if (betweenDays > 5 || betweenDays < 0) {
//     return 'error'
//   }

//   return error
// }

// function changeDate(time) {
//   //let date = new Date()
//   let a = time.split(':')
//   let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
//   //date = date.setSeconds(date.getSeconds() + seconds)
//   return seconds
// }

// function minsBetween(days, sec) {
//   var duration = days * 24 * 60 * 60 * 1000 + sec
//   return duration / (1000 * 60 * 60 * 24)
// }
