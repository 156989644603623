/**
 * 稼動時間パターンデータ.
 *
 * @class
 * @exports
 */
export default class operationTimePatternDetail {
  //--------------TAB1------------//
  operationTimePatternDetailId = null
  displayOrder = null
  transportTypeKind = null
  transportTypeKindName = null
  autoPlanningExcludeFlag = 0
  autoPlanningExcludeFlagName = null
  beginWeekday = 1
  beginWeekdayName = null
  beginTime = '00:00:00'
  endWeekday = 1
  endWeekdayName = null
  endTime = '00:00:00'
  chargeAreaId01 = null
  chargeAreaName01 = null
  chargeAreaId02 = null
  chargeAreaName02 = null
  chargeAreaId03 = null
  chargeAreaName03 = null
  chargeAreaId04 = null
  chargeAreaName04 = null
  chargeAreaId05 = null
  chargeAreaName05 = null
  deleted = 0
  registrationTime = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTime = null
  updateTimeDisplay = null
  updateUser = null
  version = 0
  updateByTrigger = null
}
