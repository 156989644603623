<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="operationTimePatternDetail"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 追加ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.add"
          @click="add(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- Areaモーダル -->
    <AreaModal
      id="area-modal"
      type="entry"
      @after-close-set="afterCloseSetArea"
      @after-close-unset="afterCloseUnsetArea"
    />
  </div>
</template>

<script>
import OperationTimePatternDetail from './OperationTimePatternDetailInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { INTEGER_MAX_MIN } from '@/common/const.js'
import {
  init,
  add,
} from '@/master/operationTimePattern/OperationTimePatternDetailEdit.js'

export default {
  components: {
    AreaModal: () => import('@/master/area/AreaModal.vue'),
  },
  props: {
    selectedItem: {
      type: Object,
    },
    screenKbn: {
      type: Number,
    },
    getDatas: {
      type: Array,
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      operationTimePatternDetail: new OperationTimePatternDetail(),
      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],
      modalKbn: 0,
      obj: this,
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 稼動時間パターン明細ＩＤ
              type: 'text',
              id: 'operationTimePatternDetailId',
              columnInfo: this.detailInfo.operation_time_pattern_detail_id,
            },
            {
              // 表示順
              type: 'text',
              id: 'displayOrder',
              columnInfo: this.detailInfo.display_order,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 輸送形態区分
              type: 'select',
              id: 'transportTypeKind',
              columnInfo: this.detailInfo.transport_type_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_TYPE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 開始曜日
              type: 'select',
              id: 'beginWeekday',
              columnInfo: this.detailInfo.begin_weekday,
              options: getListOptions(this.MASTER_CODE.WEEKDAY_NAME),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 開始時刻
              type: 'time',
              id: 'beginTime',
              columnInfo: this.detailInfo.begin_time,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 終了曜日
              type: 'select',
              id: 'endWeekday',
              columnInfo: this.detailInfo.end_weekday,
              options: getListOptions(this.MASTER_CODE.WEEKDAY_NAME),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 終了時刻
              type: 'time',
              id: 'endTime',
              columnInfo: this.detailInfo.end_time,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 担当方面１
              type: 'referenceCodeName',
              codeId: 'chargeAreaId01',
              nameId: 'chargeAreaName01',
              codeColumnInfo: this.detailInfo.charge_area_id01,
              nameColumnInfo: this.detailInfo.charge_area_name01,
              btnColumnInfo: this.detailInfo.sub_modal_area_01,
              onClickCallback: () => {
                this.openSubModal(this, 'area-modal')
                this.modalKbn = 1
              },
            },
            {
              // 担当方面２
              type: 'referenceCodeName',
              codeId: 'chargeAreaId02',
              nameId: 'chargeAreaName02',
              codeColumnInfo: this.detailInfo.charge_area_id02,
              nameColumnInfo: this.detailInfo.charge_area_name02,
              btnColumnInfo: this.detailInfo.sub_modal_area_02,
              onClickCallback: () => {
                this.openSubModal(this, 'area-modal')
                this.modalKbn = 2
              },
            },
            {
              // 担当方面３
              type: 'referenceCodeName',
              codeId: 'chargeAreaId03',
              nameId: 'chargeAreaName03',
              codeColumnInfo: this.detailInfo.charge_area_id03,
              nameColumnInfo: this.detailInfo.charge_area_name03,
              btnColumnInfo: this.detailInfo.sub_modal_area_03,
              onClickCallback: () => {
                this.openSubModal(this, 'area-modal')
                this.modalKbn = 3
              },
            },
            {
              // 担当方面４
              type: 'referenceCodeName',
              codeId: 'chargeAreaId04',
              nameId: 'chargeAreaName04',
              codeColumnInfo: this.detailInfo.charge_area_id04,
              nameColumnInfo: this.detailInfo.charge_area_name04,
              btnColumnInfo: this.detailInfo.sub_modal_area_04,
              onClickCallback: () => {
                this.openSubModal(this, 'area-modal')
                this.modalKbn = 4
              },
            },
            {
              // 担当方面５
              type: 'referenceCodeName',
              codeId: 'chargeAreaId05',
              nameId: 'chargeAreaName05',
              codeColumnInfo: this.detailInfo.charge_area_id05,
              nameColumnInfo: this.detailInfo.charge_area_name05,
              btnColumnInfo: this.detailInfo.sub_modal_area_05,
              onClickCallback: () => {
                this.openSubModal(this, 'area-modal')
                this.modalKbn = 5
              },
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },

            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
      ]
    },

    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.OPERATION_TIME_PATTERN_EDIT_MODAL
      )('detail_info')
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.OPERATION_TIME_PATTERN_EDIT_MODAL
      )('result_ctrl_info')
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    add,

    afterCloseSetArea(id, name) {
      if (this.modalKbn == 1) {
        this.obj.operationTimePatternDetail.chargeAreaId01 = id
        this.obj.operationTimePatternDetail.chargeAreaName01 = name
      } else if (this.modalKbn == 2) {
        this.obj.operationTimePatternDetail.chargeAreaId02 = id
        this.obj.operationTimePatternDetail.chargeAreaName02 = name
      } else if (this.modalKbn == 3) {
        this.obj.operationTimePatternDetail.chargeAreaId03 = id
        this.obj.operationTimePatternDetail.chargeAreaName03 = name
      } else if (this.modalKbn == 4) {
        this.obj.operationTimePatternDetail.chargeAreaId04 = id
        this.obj.operationTimePatternDetail.chargeAreaName04 = name
      } else if (this.modalKbn == 5) {
        this.obj.operationTimePatternDetail.chargeAreaId05 = id
        this.obj.operationTimePatternDetail.chargeAreaName05 = name
      }
    },
    afterCloseUnsetArea() {
      if (this.modalKbn == 1) {
        this.obj.operationTimePatternDetail.chargeAreaId01 = null
        this.obj.operationTimePatternDetail.chargeAreaName01 = null
      } else if (this.modalKbn == 2) {
        this.obj.operationTimePatternDetail.chargeAreaId02 = null
        this.obj.operationTimePatternDetail.chargeAreaName02 = null
      } else if (this.modalKbn == 3) {
        this.obj.operationTimePatternDetail.chargeAreaId03 = null
        this.obj.operationTimePatternDetail.chargeAreaName03 = null
      } else if (this.modalKbn == 4) {
        this.obj.operationTimePatternDetail.chargeAreaId04 = null
        this.obj.operationTimePatternDetail.chargeAreaName04 = null
      } else if (this.modalKbn == 5) {
        this.obj.operationTimePatternDetail.chargeAreaId05 = null
        this.obj.operationTimePatternDetail.chargeAreaName05 = null
      }
    },
  },
}
</script>

<style scoped></style>
